import React, { useState } from "react";

import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "gatsby";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useStaticQuery, graphql } from "gatsby";
import footerlogo from "../assets/footer-logo.png";
import footercurve from "../assets/footer-curve.svg";
import twitter from "../assets/tw.png";
import facebook from "../assets/fb.png";
import youtube from "../assets/yt.png";
import instagram from "../assets/instagram.png";
import linkedin from "../assets/lkn.png";
import "./footer.scss";

const SignupSchema = Yup.object().shape({
  fullname: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .matches(/^[aA-zZ\s]+$/, "Please enter valid name")
    .required("Full Name is required"),
  school: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .matches(/^[aA-zZ\s]+$/, "Please enter valid School name")
    .required("School is required"),
  tel: Yup.string()
    .required("Phone number is required")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Invalid phone number"
    ),

  email: Yup.string().email().required("Email is required"),
});
function Footer() {
  const footerPageData = useStaticQuery(graphql`
    query {
      allStrapiPageFooter {
        nodes {
          BookADemo {
            Content
            Title
            id
          }
          ContactUs {
            Address
            EmailAddress
            PhoneNumber
            Title
            id
          }
          id
        }
      }
    }
  `);
  const footerData = footerPageData.allStrapiPageFooter.nodes[0];

  const [name, setName] = useState("");
  const [school, setSchool] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const recaptchaRef = React.createRef();

  console.log(footerData);
  let url = "https://throtari.com/emailData.php";
  // let testUrl = 'http://localhost:3000/emailData.php'

  const handleSend = async (e) => {
    e.preventDefault();

    try {
      setSent(true);

      var templateParams = {
        FullName: name,
        School: school,
        Phone: phone,
        Email: email,
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios
        .post(url, templateParams)
        .then((response) => {
          console.log("message sent");

          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }

    document.querySelector("#bookingForm").reset();
  };

  const onChange = (value) => {
    if (value === "" || value === null) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  };

  return (
    <footer className="footer" id="book">
      <img
        src={footercurve}
        className="footer-curve"
        alt="background"
        width="100%"
      ></img>
      <Container>
        <Row>
          <Col md>
            <img
              src={footerlogo}
              alt="logo"
              className="footer-logo"
              width="120"
            ></img>
            <div className="footer-form-contact">
              <h3>{footerData.ContactUs[0].Title}</h3>
            </div>
            <ul>
              <li>
                <a href="tel:`${footerData.ContactUs[0].PhoneNumber}`">
                  <span>t</span>
                  {footerData.ContactUs[0].PhoneNumber}
                </a>
              </li>
              <li>
                <span>e</span>
                <a href="mailto:info@feoda.com">
                  {" "}
                  {footerData.ContactUs[0].EmailAddress}
                </a>
              </li>
              <li>
                <span>a</span> {footerData.ContactUs[0].Address}
              </li>
            </ul>
            <br></br>
            <a
              href="https://www.youtube.com/channel/UCcluHReMreCFdkDE8b-qrWA"
              target="_blank"
              rel="noreferrer"
            >
              <img src={youtube}></img>
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a
              href="https://twitter.com/FeodaEducation"
              target="_blank"
              rel="noreferrer"
            >
              <img src={twitter}></img>
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a
              href="https://www.linkedin.com/company/feoda/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkedin}></img>
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a
              href="https://www.facebook.com/Feoda-107651064865815"
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebook}></img>
            </a>
          </Col>
          <Col md className="form-wrapper">
            <div className="footer-form-demo">
              <h2>{footerData.BookADemo[0].Title}</h2>
            </div>
            <ReactMarkdown source={footerData.BookADemo[0].Content} />

            {/* <p>
              See how our finance management solution can transform your school.
              <br />
              <br /> Please complete the required fields and tick the box below
              before submitting.{" "}
            </p> */}

            <Formik
              initialValues={{
                fullname: "",
                school: "",
                tel: "",
                email: "",
              }}
              validationSchema={SignupSchema}
              onSubmit={(values) => {
                // same shape as initial values
                console.log(values);
                axios
                  .post(url, values)
                  .then((response) => {
                    console.log("message sent");
                    setSent(true);
                    document.querySelector("#bookingForm").reset();

                    console.log(response);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >
              {({ errors, touched, isValid, dirty }) => (
                <Form>
                  <Field
                    name="fullname"
                    className="form-control"
                    placeholder="Full Name*"
                    style={{ marginBottom: "1rem" }}
                  />
                  {errors.fullname && touched.fullname ? (
                    <div className="alert alert-danger">{errors.fullname}</div>
                  ) : null}
                  <Field
                    name="school"
                    className="form-control"
                    placeholder="School*"
                    style={{ marginBottom: "1rem" }}
                  />
                  {errors.school && touched.school ? (
                    <div className="alert alert-danger">{errors.school}</div>
                  ) : null}
                  <Field
                    name="tel"
                    className="form-control"
                    placeholder="Phone Number"
                    style={{ marginBottom: "1rem" }}
                  />
                  {errors.tel && touched.tel ? (
                    <div className="alert alert-danger">{errors.tel}</div>
                  ) : null}
                  <Field
                    name="email"
                    type="email"
                    className="form-control"
                    placeholder="Email*"
                    style={{ marginBottom: "1rem" }}
                  />
                  {errors.email && touched.email ? (
                    <div className="alert alert-danger">{errors.email}</div>
                  ) : null}

                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6Le1G1oaAAAAAIn0ypwhMdHbWSxFnMGb8N7fVVuf"
                    onChange={onChange}
                  />
                  <div>
                  <Button variant="primary" type="submit" disabled={isDisabled}>
                    Submit
                  </Button>
                  </div>
                </Form>
              )}
            </Formik>

            <div>
              {sent ? (
                <div className="footer-messagesent">
                  Thanks for submitting a Request
                </div>
              ) : null}
            </div>
          </Col>
        </Row>
      </Container>

      <section className="footer-bottom">
        <Container>
          <div className="footer-bottom__wrapper">
            <ul>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
            </ul>
            <span>&copy; 2021 FEODA Pty Ltd</span>
          </div>
        </Container>
      </section>
    </footer>
  );
}

export default Footer;
