import React, { useState } from "react";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import { Container, Navbar, Nav, Button } from "react-bootstrap";
import "./header.scss";
import logo from "../../assets/feoda-logo.png";
import linkedin from "../../assets/linkedin.png";
import line from "../../assets/line.png";

const Header = () => {
  const [isNavExpanded, setisNavExpanded] = useState(false);

  const toggleHandle = () => {
    if (isNavExpanded) {
      setisNavExpanded(false);
    } else {
      setisNavExpanded(true);
    }
  };

  const closeNavBar = () => {
    setisNavExpanded(false);
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="header"
      sticky="top"
      onToggle={toggleHandle}
      expanded={isNavExpanded}
    >
      <Container fluid>
        <Navbar.Brand>
          <Link to="/">
            <img src={logo} alt="Feoda" width="140"></img>
          </Link>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="navigation" />

        <Navbar.Collapse id="navigation">
          <Nav className="navbar">
            <div className="nav-item">
              <AnchorLink
                to="/"
                className="nav-link dropdown-toggle"
                activeClassName="active"
              >
                Solutions
              </AnchorLink>
              <div className="dropdown-menu">
                <div className="dropdown-item">
                  <span className="menu">
                    &nbsp;&nbsp;&nbsp;&nbsp;feoda{" "}
                    <span className="menu1">Flow</span>
                  </span>
                </div>
                <div className="dropdown-item">
                  <span className="menu">
                    &nbsp;&nbsp;&nbsp;&nbsp;feoda{" "}
                    <span className="menu1">Finance</span>
                  </span>
                </div>
                <div className="dropdown-item">
                  <span className="menu">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;feoda{" "}
                    <span className="menu1">Fusion</span>
                  </span>
                </div>
                <div className="dropdown-item">
                  <Link
                    to="/Online"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Online
                  </Link>
                  <Link
                    to="/Engage"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Engage
                  </Link>
                  <Link
                    to="/enrol"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Enrol
                  </Link>
                </div>
                <img src={line} class="img" height="100"></img>
                <div className="dropdown-item">
                  <Link
                    to="/Billing"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Billing
                  </Link>
                  <Link
                    to="/Procure"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Procure to Pay
                  </Link>
                  <Link
                    to="/Assets"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Assets
                  </Link>
                </div>
                &nbsp;
                <img src={line} class="img" height="100"></img>
                <div className="dropdown-item">
                  <Link
                    to="/Budget"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Budgets
                  </Link>
                  <Link
                    to="/Analytics"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Analytics
                  </Link>
                  <Link
                    to="/Integrate"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Integrate
                  </Link>
                </div>
              </div>
            </div>

            <div className="nav-item" onClick={closeNavBar}>
              <AnchorLink
                to="/#benefits"
                className="nav-link"
                activeClassName="active"
              >
                Benefits
              </AnchorLink>
            </div>
            <div className="nav-item">
              <Link
                to="/case-study"
                onClick={closeNavBar}
                className="nav-link"
                activeClassName="active"
              >
                Case Study
              </Link>
            </div>
            <div className="nav-item">
              <Link
                to="/oracle"
                onClick={closeNavBar}
                className="nav-link"
                activeClassName="active"
              >
                About Oracle
              </Link>
            </div>
            <div className="nav-item">
              <Link
                to="/about-us"
                onClick={closeNavBar}
                className="nav-link"
                activeClassName="active"
              >
                About Us
              </Link>
            </div>
          </Nav>

          <section className="ctas">
            <AnchorLink to="/#book">
              <Button onClick={closeNavBar} variant="success">
                REQUEST A CHAT
              </Button>
            </AnchorLink>
          </section>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
