import React from "react";
import sectionHeaderStyles from "./sectionHeader.module.scss";
import ReactMarkdown from "react-markdown";

const SectionHeader = (props) => {
  return (
    <div className={`${sectionHeaderStyles.sectionHeader} `}>
      <img src={props.icon} width="40"></img>
      <h2>
        {props.title}
        <span className="highlight">&nbsp;{props.highlight}&nbsp;</span>
        {props.title1}
      </h2>
      <ReactMarkdown source={props.text} />
    </div>
  );
};

export default SectionHeader;
